<template>
  <el-container class="LoginBox">

    <!-- 登录页面弹框 -->
    <div class="theme-cyan">

      <!-- 联系我们 -->
      <div id="ContactUs" class="modal fade" style="display:none">
        <div>
          <div class="detail-header">
            <div class="media">
              <div class="media-body">
                <p class="mb-3"><i class="fad fa-user-headset fa-3x text-success-55"></i></p>
                <hr>
                <p class="mb-2"><strong>{{ $t("login.ContactUs.EmailTitle") }}</strong></p>
                <p class="mb-1"><small>{{ $t("login.ContactUs.EmailSonTitle1") }}</small></p>
                <p class="mb-2">
                  <el-tag type="success">
                    <span>{{ $t("login.ContactUs.EmailAddress1") }}</span>
                  </el-tag>
                </p>
                <p class="mb-1"><small>{{ $t("login.ContactUs.EmailSonTitle2") }}</small></p>
                <p class="mb-1">
                  <el-tag type="success">
                    <span>{{ $t("login.ContactUs.EmailAddress2") }}</span>
                  </el-tag>
                </p>
                <hr>
                <p class="mb-2"><strong>{{ $t("login.ContactUs.TelTitle") }}</strong></p>
                <p class="mb-1">
                  <el-tag type="success">
                    <span class="fa-layers fa-fw fa-2x mr-2 m-b--1">
                      <i class="fad fa-circle-notch fa-spin"></i>
                      <i class="fad fa-phone" data-fa-transform="shrink-8"></i>
                    </span>
                    <span class="x-large">{{ $t("login.ContactUs.TelNumber") }}</span>
                  </el-tag>
                </p>
                <p class="mb-0"><small>{{ $t("login.ContactUs.TelDate") }}</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 忘记/找回密码 -->
      <div id="verifyModal" class="modal fade bd-Findkey-modal-lg" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-search"></i>
                  <i class="fad fa-key-skeleton" data-fa-transform="shrink-11 up-1.5 left-1.8"></i>
                </span>
                <span class="TitleName">
                  <!-- 找回密码 -->
                  {{ $t("login.rp.retrievePassword") }}
                </span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" @click="registerClose" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body pricing_page">
              <div role="application" class="wizard clearfix">
                <div class="steps clearfix">
                  <ul role="tablist">
                    <li role="tab" class="first " :class="retrieve.page == 1 ? 'current' : 'disabled'" :aria-disabled="retrieve.page == 1 ? false : true" :aria-selected="retrieve.page == 1 ? true : false">
                      <a id="wizard_horizontal01-t-0" href="#wizard_horizontal01-h-0" aria-controls="wizard_horizontal01-p-0"><span class="current-info audible">current step: </span><span class="number"></span> <big>1.</big>
                        <!-- 输入用户名 -->
                        {{ $t("login.rp.enterUserName") }}
                      </a>
                    </li>
                    <li role="tab" class="last" :class="retrieve.page == 2 ? 'current' : 'disabled'" :aria-disabled="retrieve.page == 2 ? false : true" :aria-selected="retrieve.page == 2 ? true : false">
                      <a id="wizard_horizontal01-t-1" href="#wizard_horizontal01-h-1" aria-controls="wizard_horizontal01-p-1"><span class="number"></span> <big>2.</big>
                        <!-- 验证身份 -->
                        {{ $t("login.rp.authentication") }}
                      </a>
                    </li>
                    <li role="tab" class="last" :class="retrieve.page == 3 ? 'current' : 'disabled'" :aria-disabled="retrieve.page == 3 ? false : true" :aria-selected="retrieve.page == 3 ? true : false">
                      <a id="wizard_horizontal01-t-2" href="#wizard_horizontal01-h-2" aria-controls="wizard_horizontal01-p-1"><span class="number"></span> <big>3.</big>
                        <!-- 重置密码 -->
                        {{ $t("login.rp.resetPassword") }}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="content clearfix" style="height:290px">
                  <h2 id="wizard_horizontal01-h-0" tabindex="-1" :class="retrieve.page == 1 ? 'current' : ''" class="title ">
                    <big>1.</big>
                    <!-- 输入用户名 -->
                    {{ $t("login.rp.pleaseEnterUserName") }}
                  </h2>
                  <section id="wizard_horizontal01-p-0" role="tabpanel" aria-labelledby="wizard_horizontal01-h-0" :class="retrieve.page == 1 ? 'current' : ''" class="body" aria-hidden="false" :style="{ left: (1 - retrieve.page) * 764 + 'px' }">
                    <form class="form-auth-small  margin-center mt-5">
                      <div class="form-group c_form_group">
                        <label>
                          <!-- 用户名 -->
                          {{ $t("login.rp.userName") }}
                          <span v-show="errors.has('verify.verifyUserCode')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                            <span class="text-tomato small">
                              {{ errors.first("verify.verifyUserCode") }}
                            </span></span>
                        </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fad fa-user-circle text-dark-15"></i></span>
                          </div>
                          <input data-vv-scope="verify" v-validate="'required|verifyUser'" type="text" v-model="sendForm.verifyUserCode" name="verifyUserCode" :placeholder="$t('login.rp.pleaseEnterUserName')" class="form-control email" />
                        </div>
                      </div>
                    </form>
                  </section>
                  <h2 id="wizard_horizontal01-h-1" tabindex="-1" :class="retrieve.page == 2 ? 'current' : ''" class="title">
                    <big>2.</big>
                    <!-- 验证身份 -->
                    {{ $t("login.rp.authentication") }}
                  </h2>
                  <section id="wizard_horizontal01-p-1" role="tabpanel" aria-labelledby="wizard_horizontal01-h-1" :class="retrieve.page == 2 ? 'current' : ''" class="body" aria-hidden="true" :style="{ left: (2 - retrieve.page) * 764 + 'px' }">
                    <div class="row col-12">
                      <div class="col-4 border-right mt-4">
                        <p>
                          <big>
                            <!-- 自助邮箱验证 -->
                            {{ $t("login.rp.autoMailValidate") }}
                          </big>
                        </p>
                        <p>
                          <i class="fad fa-envelope-open-text fa-3x text-success-55"></i>
                        </p>
                        <p>
                          <small>{{ sendLodaingMsg }}</small>
                        </p>
                        <el-button size="small" type="success" @click="sendEmail" :loading="sendLodaing" round plain>
                          <!-- 立即发送 / 请稍后 -->
                          {{  sendLodaing?$t("login.rp.sendWaite"):$t("login.rp.sendNow") }}
                        </el-button>
                        <!-- <button type="button" @click="sendEmail" data-type="success" class="btn btn-success btn-sm btn-round" v-loading="sendLodaing">
                          {{ $t("login.rp.sendNow") }}
                        </button> -->
                      </div>
                      <div class="col-4 border-right mt-4">
                        <p>
                          <big>
                            <!-- 已收到验证码 -->
                            {{ $t("login.rp.receivedCode") }}
                          </big>
                        </p>
                        <p>
                          <i class="fad fa-barcode-read fa-3x text-success-55"></i>
                        </p>
                        <p>
                          <small>
                            <!-- 请复制注册邮箱中的激活码 -->
                            {{ $t("login.rp.copyAcitveKey") }}
                          </small>
                        </p>
                        <el-button size="small" type="success" @click="resetPwd" round plain>
                          <!-- 重置密码 -->
                          {{ $t("login.rp.resetPassword") }}
                        </el-button>
                        <!-- <button type="button" @click="resetPwd" data-type="success" class="btn btn-success btn-sm btn-round">
                          {{ $t("login.rp.resetPassword") }}
                        </button> -->
                      </div>
                      <div class="col-4 mt-4">
                        <p>
                          <big>
                            <!-- 人工服务 -->
                            {{ $t("login.rp.artificialService") }}
                          </big>
                        </p>
                        <p>
                          <i class="fad fa-user-headset fa-3x text-success-55"></i>
                        </p>
                        <p>
                          <small>
                            <!-- 联系我们以找回密码 -->
                            {{ $t("login.rp.contactInfo") }}
                          </small>
                        </p>
                        <el-button size="small" type="success" @click="handleContact" round plain>
                          <!-- 联系我们 -->
                          {{ $t("login.rp.contactUs") }}
                        </el-button>
                      </div>
                    </div>
                  </section>

                  <h2 id="wizard_horizontal01-h-2" tabindex="-1" :class="retrieve.page == 3 ? 'current' : ''" class="title">
                    <big>3.</big>
                    <!-- 重置密码 -->
                    {{ $t("login.rp.resetPassword") }}
                  </h2>
                  <section id="wizard_horizontal01-p-2" role="tabpanel" aria-labelledby="wizard_horizontal01-h-2" :class="retrieve.page == 3 ? 'current' : ''" class="body" aria-hidden="false" :style="{ left: (3 - retrieve.page) * 764 + 'px' }">
                    <form class="form-auth-small  margin-center ">
                      <div>
                        <div class="form-group c_form_group">
                          <label>
                            <!-- 验证码 -->
                            {{ $t("login.rp.acitveKey") }}
                            <span v-show="errors.has('reset.verifyKey')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                              <span class="text-tomato small">
                                {{ errors.first("reset.verifyKey") }}
                              </span></span>
                          </label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fad fa-barcode-scan text-dark-15"></i></span>
                            </div>
                            <!-- 请输入验证码 -->
                            <input type="text" class="form-control email" data-vv-scope="reset" v-validate="'required'" v-model="resetForm.verifyKey" name="verifyKey" :placeholder="$t('login.rp.pleaseAcitveKey')" />
                          </div>
                        </div>
                      </div>

                      <div class="mb-3">
                        <div class="form-group c_form_group">
                          <label>
                            <!-- 重置密码 -->
                            {{ $t("login.rp.resetPassword") }}
                            <span v-show="errors.has('reset.password')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                              <span class="text-tomato small">
                                {{ errors.first("reset.password") }}
                              </span></span>
                          </label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fad fa-key-skeleton text-dark-15"></i></span>
                            </div>
                            <!-- 请输入重置密码 -->
                            <input data-vv-scope="reset" v-validate="'required|min:8|pwd'" type="password" v-model="resetForm.password" name="password" :placeholder="$t('login.rp.pleaseResetPassword')" class="form-control email" />
                          </div>
                        </div>
                        <div class="form-group c_form_group">
                          <label>
                            <!-- 确认密码 -->
                            {{ $t("login.rp.confirmPassword") }}
                            <span v-show="errors.has('reset.resetPassword')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                              <span class="text-tomato small">
                                {{ errors.first("reset.resetPassword") }}
                              </span></span>
                          </label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <span class="fa-layers fa-fw">
                                  <i class="fad fa-key-skeleton text-dark-15"></i>
                                  <i class="fad fa-check" data-fa-transform="shrink-4 right-8 down-3"></i>
                                </span>
                              </span>
                            </div>
                            <!-- 请输入确认密码 -->
                            <input data-vv-scope="reset" v-validate="'required|confirmed:password'" type="password" v-model="resetForm.resetPassword" name="resetPassword" :placeholder="
                                $t('login.rp.pleaseConfirmPassword')
                              " class="form-control email" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>

                <!-- <div class="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li
                      :style="{
                        display: retrieve.page == 1 ? 'none' : 'block'
                      }" aria-disabled="true">
                      <a @click="previous" class="previous" href="javascript:void(0)" role="menuitem">
                        {{ $t("login.rp.prev") }}
                      </a>
                    </li>
                    <li
                      :style="{
                        display:
                          retrieve.page == 2 || retrieve.page == 3
                            ? 'none'
                            : 'block'
                      }" aria-hidden="false" aria-disabled="false">
                      <a @click="next" class="" href="javascript:void(0)" role="menuitem" v-loading="nextLoading">
                        {{ $t("login.rp.next") }}
                      </a>
                    </li>
                    <li
                      aria-hidden="true"
                      :style="{
                        display: retrieve.page == 3 ? 'block' : 'none'
                      }">
                      <a class="finish" href="javascript:void(0)" @click="retrievePwd" role="menuitem">
                        {{ $t("login.rp.edit") }}
                      </a>
                    </li>
                  </ul>
                </div> -->

              </div>
            </div>

            <div class="modal-footer" role="menu" aria-label="Pagination">
              <button @click="previous" type="button" role="menuitem" class="btn btn-primary theme-bg gradient" :style="{
                        display: retrieve.page == 1 ? 'none' : 'block'
                      }" aria-disabled="true">
                <!-- 上一步 -->
                {{ $t("login.rp.prev") }}
              </button>
              <button @click="next" type="button" role="menuitem" class="btn btn-primary theme-bg gradient" v-loading="nextLoading" :style="{
                        display:
                          retrieve.page == 2 || retrieve.page == 3
                            ? 'none'
                            : 'block'
                      }" aria-hidden="false" aria-disabled="false">
                <!-- 下一步 -->
                {{ $t("login.rp.next") }}
              </button>
              <button @click="retrievePwd" type="button" role="menuitem" class="btn btn-primary theme-bg gradient" :style="{
                        display: retrieve.page == 3 ? 'block' : 'none'
                      }" aria-hidden="true">
                <!-- 修改 -->
                {{ $t("login.rp.edit") }}
              </button>
            </div>

          </div>
        </div>
      </div>

      <!-- 注册 -->
      <div id="registerModal" class="modal fade bd-Register-modal-lg" data-backdrop='static' tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="overflow:auto;overflow-y:auto;overflow-x:hidden">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-id-card"></i>
                  <i class="fad fa-edit" data-fa-transform="shrink-8 right-6"></i>
                </span>
                <span class="TitleName">
                  <!-- 注册新用户 -->
                  {{ $t("login.reg.registerUsers") }}
                </span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" @click="registerClose" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <form>
              <div class="modal-body text-left">
                <h6 class="enforce">
                  <!-- 选择用户类型 -->
                  {{ $t("login.reg.userType") }}
                </h6>
                <div class="mb-3" id="Type">
                  <template v-for="item in userTypes">
                    <label :key="item.userTypeId" class="fancy-radio custom-color-green"><input name="userType" :value="item.userTypeId" v-model.trim="registerForm.userTypeId" type="radio" /><span><i></i> {{ item.userTypeName }}</span></label>
                  </template>
                </div>
                <h6 class="enforce">
                  <!-- 用户名密码设置 -->
                  {{ $t("login.reg.userInfoSetting") }}
                </h6>
                <div class="mb-3" id="UserName">
                  <div class="form-group c_form_group">
                    <label>
                      <!-- 用户名 -->
                      {{ $t("login.reg.userName") }}
                      <span v-show="errors.has('register.userCode')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato small">
                          {{ errors.first("register.userCode") }}
                        </span></span>
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-user-circle text-dark-15"></i></span>
                      </div>
                      <!-- 请输入用户名 -->
                      <input data-vv-scope="register" type="text" v-validate="'required|min:8|max:50|zzts|repeatUser'" name="userCode" class="form-control email" v-model.trim="registerForm.userCode" :placeholder="$t('login.reg.pleaseUserName')" />
                    </div>
                  </div>
                </div>
                <div class="mb-3" id="Password">
                  <div class="form-group c_form_group">
                    <label>
                      <!-- 密码 -->
                      {{ $t("login.reg.password") }}
                      <span v-show="errors.has('register.userPassword')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato small">
                          {{ errors.first("register.userPassword") }}
                        </span></span>
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-key-skeleton text-dark-15"></i></span>
                      </div>
                      <!-- 请输入密码 -->
                      <input data-vv-scope="register" :type="passwordFlag ? 'password' : 'text'" v-validate="'required|min:8|max:50|pwd'" name="userPassword" class="form-control email" v-model.trim="registerForm.userPassword" :placeholder="$t('login.reg.pleasePassword')" />
                      <div class="input-group-prepend float-right">
                        <span :class="passwordFlag ? 'unsee' : 'see'" @click="passwordSee"></span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group c_form_group">
                    <label>
                      <!-- 确认密码 -->
                      {{ $t("login.reg.confirmPassword") }}
                      <span v-show="errors.has('register.repeatUserPassword')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato small">
                          {{ errors.first("register.repeatUserPassword") }}
                        </span></span>
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <span class="fa-layers fa-fw">
                            <i class="fad fa-key-skeleton text-dark-15"></i>
                            <i class="fad fa-check" data-fa-transform="shrink-4 right-8 down-3"></i>
                          </span>
                        </span>
                      </div>
                      <!-- 请再次输入密码 -->
                      <input data-vv-scope="register" :type="passwordFlag ? 'password' : 'text'" @paste="paste" v-validate="'required|confirmed:userPassword'" name="repeatUserPassword" class="form-control email" v-model.trim="registerForm.repeatUserPassword" :placeholder="$t('login.reg.pleaseConfirmPassword')" />
                      <div class="input-group-prepend float-right">
                        <span :class="passwordFlag ? 'unsee' : 'see'" @click="passwordSee"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 class="enforce">
                  <!-- 电子邮箱确认 -->
                  {{ $t("login.reg.confirmEmailSetting") }}
                </h6>
                <div class="mb-3" id="Email">
                  <div class="form-group c_form_group">
                    <label>
                      <!-- 邮箱地址 -->
                      {{ $t("login.reg.email") }}
                      <span v-show="errors.has('register.userEmail')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato small">
                          {{ errors.first("register.userEmail") }}
                        </span></span>
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-envelope text-dark-15"></i></span>
                      </div>
                      <input data-vv-scope="register" type="text" v-validate="'required|email|repeatEmail'" name="userEmail" class="form-control email" v-model.trim="registerForm.userEmail" placeholder="Ex: example@example.com" />
                    </div>
                  </div>
                </div>
                <div class="mb-3" id="Email">
                  <div class="form-group c_form_group">
                    <label>
                      <!-- 确认邮箱地址 -->
                      {{ $t("login.reg.confirmEmail") }}
                      <span v-show="errors.has('register.repeatUserEmail')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato small">
                          {{ errors.first("register.repeatUserEmail") }}
                        </span></span>
                      <!-- <span class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i> <span class="text-tomato small">禁止复制粘贴</span></span> -->
                    </label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <span class="fa-layers fa-fw">
                            <i class="fad fa-envelope text-dark-15"></i>
                            <i class="fad fa-check" data-fa-transform="shrink-4 right-8 down-3"></i>
                          </span>
                        </span>
                      </div>
                      <input data-vv-scope="register" type="text" @paste="paste" v-validate="'required|confirmed:userEmail'" name="repeatUserEmail" class="form-control email" v-model.trim="registerForm.repeatUserEmail" placeholder="Ex: example@example.com" />
                    </div>
                  </div>
                </div>
                <h6>
                  <!-- 联系方式 -->
                  {{ $t("login.reg.contactInfo") }}
                </h6>

                <div class=" mb-3" id="MobilePhone">
                  <div class="form-group c_form_group">
                    <label>
                      <!-- 用户名 -->
                      {{ $t("login.reg.contactNumber") }}
                      <span v-show="errors.has('register.userTelephone')" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato small">
                          {{ errors.first("register.userTelephone") }}
                        </span></span>
                    </label>

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fad fa-mobile-android-alt text-dark-15"></i></span>
                      </div>
                      <input data-vv-scope="register" type="text" v-validate="'telephone'" name="userTelephone" v-model.trim="registerForm.userTelephone" class="form-control mobile-phone-number" placeholder="Ex: +00 (000) 000-00-00" />
                    </div>
                  </div>
                </div>

                <!-- 条款 -->
                <div class="form-group clearfix element-left">
                  <label class="switch">
                    <input type="checkbox" v-model.trim="registerForm.checked" />
                    <span class="slider round"></span>
                  </label>
                  <span class="ml-2">
                    <!-- 我已阅读并接受隐私条款 -->
                    {{ $t("login.reg.accept") }}
                  </span>
                </div>
                <ul class="accordion2">
                  <li class="accordion-item">
                    <!--class "is-active"-->
                    <h3 class="accordion-thumb">
                      <small class="text-primary">
                        <!-- 查看隐私条款 -->
                        {{ $t("login.reg.viewClause") }}
                      </small>
                    </h3>
                    <p class="accordion-panel" v-html="$t('login.reg.clauseInfo')">
                      <!-- 隐私条款请自行翻译 -->
                      <!-- {{$t('login.reg.clauseInfo')}} -->
                    </p>
                  </li>
                </ul>
              </div>
            </form>

            <div class="modal-footer">
              <button type="button" class="btn btn-default " @click="registerClose" data-dismiss="modal">
                <!-- 关闭 -->
                {{ $t("login.reg.closeBut") }}
              </button>

              <el-button type="button" :loading="registerSubmit" :disabled="registerSubmit" @click="register" class="btn btn-primary theme-bg gradient js-sweetalert" data-type="success" style="padding:6px 18px;">
                <!-- 注册 -->{{registerTitle}}
              </el-button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <el-header>
      <div class="choose">
        <ul class="nav navbar-nav">
          <li class="dropdown globe-menu nation">
            <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
              <i class="fad fa-globe-asia fa-spin" style="font-size:larger"></i>
            </a>
            <div class="dropdown-menu nation_widget">
              <a class="dropdown-item" href="javascript:void(0);"><img src="../assets/images/flag/Japan.svg" alt="Japan" />Japan</a>
              <a class="dropdown-item" href="javascript:void(0);"><img src="../assets/images/flag/China.svg" alt="China" />China</a>
              <a class="dropdown-item" href="javascript:void(0);"><img src="../assets/images/flag/Malaysia.svg" alt="Malaysia" />Malaysia</a>
            </div>
          </li>
          <li class="dropdown language-menu language">
            <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
              <i class="fad fa-language" style="font-size:larger"></i>
            </a>
            <div class="dropdown-menu language_widget">
              <a class="dropdown-item" href="javascript:void(0);" @click="changeLocale('ja')"><img src="../assets/images/flag/Japanese.svg" alt="日本語" />日本語</a>
              <a class="dropdown-item" href="javascript:void(0);" @click="changeLocale('zh_CN')"><img src="../assets/images/flag/Chinese.svg" alt="中文" />中文</a>
              <a class="dropdown-item" href="javascript:void(0);" @click="changeLocale('en')"><img src="../assets/images/flag/English.svg" alt="English" />English</a>
            </div>
          </li>
        </ul>
      </div>
    </el-header>

    <el-aside>
      <el-row>
        <el-col :span="24"><img src="../assets/images/SmartOM.svg" class="LoginBrand" /></el-col>
        <el-col :span="24">
          <div class="Version">V.2.2.6</div>
        </el-col>
        <el-col :span="24">
          <div class="SystemName">
            {{ $t("login.slogan") }}
            <!-- SmartOM 蓄电池远程监控系统 -->
          </div>
        </el-col>
        <el-col :span="24">
          <div class="LoginMain">
            <form class="form-auth-small" v-if="!isLogin">
              <div class="LoginInput">
                <div class="form-group c_form_group">
                  <label>
                    <!-- 用户名 -->
                    {{ $t("login.userCode") }}
                    <span v-if="loginVerify.userCodeVerify" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        <!-- 用户名不能为空 -->
                        {{ $t("login.userCodeIsEmpty") }}
                      </span></span>
                  </label>
                  <input type="text" class="form-control" v-model.trim="loginForm.userCode" id="userCode" name="userCode" :placeholder="$t('login.userCodePlaceholder')" />
                </div>
                <div class="form-group c_form_group">
                  <label class="form-label d-block">
                    <!-- 密码 -->
                    {{ $t("login.userPassword") }}
                    <span v-if="loginVerify.userPasswordVerify" class="float-right text-tomato"><i class="fad fa-exclamation-circle"></i>
                      <span class="text-tomato small">
                        <!-- 密码不能为空 -->
                        {{ $t("login.userPasswordIsEmpty") }}
                      </span></span>
                  </label>
                  <div class="input-group">
                    <input :type="passwordFlag ? 'password' : 'text'" class="form-control" v-model.trim="loginForm.userPassword" id="userPassword" name="userPassword" :placeholder="$t('login.userPasswordPlaceholder')" @keyup.enter="login" />

                    <span :class="passwordFlag ? 'unsee' : 'see'" @click="passwordSee"></span>
                  </div>
                </div>
                <button type="button" @click="login" :disabled="isSubmit" class="btn btn-lg gradient btn-block js-sweetalert" data-type="with-title">
                  <!-- 确认登录 -->
                  {{ $t("login.signIn") }}
                </button>
              </div>
              <div class="Bottom">
                <span class="helper-text m-b-10">
                  <a href="javascript:void(0);" data-toggle="modal" data-target=".bd-Findkey-modal-lg">
                    <i class="fad fa-key-skeleton"></i>
                    <!-- 忘记密码 -->
                    {{ $t("login.forgetPassword") }}
                  </a>
                </span>
                <p>
                  <span>
                    <!-- 还没有账号？ -->
                    {{ $t("login.noAccount") }}
                  </span>
                  <a href="javascript:void(0);" data-toggle="modal" data-target=".bd-Register-modal-lg"><i class="fad fa-id-card"></i>
                    <!-- 注册一个账号 -->
                    {{ $t("login.registerAccount") }}
                  </a>
                </p>
              </div>
            </form>

            <form class="form-auth-small" v-if="isLogin">
              <el-row :gutter="10">
                <el-col :span="24" class="mb-5">
                  <!-- 欢迎回来，-->
                  {{ $t("login.welcomeBack") }}
                  <span> {{ userName }}</span>
                </el-col>
                <el-col :span="12">
                  <button type="button" @click="LoginFree" class="btn btn-lg gradient btn-block js-sweetalert" data-type="with-title">
                    <!-- 进入 -->
                    {{ $t("login.into") }}
                  </button>
                  <canvas id="canvas"></canvas>
                </el-col>
                <el-col :span="12">
                  <button type="button" @click="signOut" class="btn btn-lg gradient btn-block js-sweetalert" data-type="with-title">
                    <!-- 退出 -->
                    {{ $t("login.signOut") }}
                  </button>
                </el-col>
              </el-row>
            </form>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="LoginFooter">
            <i class="fal fa-copyright"></i>2020-2022 TAOKE ENERGY 株式会社
          </div>
        </el-col>
      </el-row>
    </el-aside>
    <video id="myVideo" muted src="https://www.smartom.com/img/Cloudy.mov" autoplay loop class="myVideo"></video>
  </el-container>
</template> 

<script>
$(function() {
  // (Optional) Active an item if it has the class "is-active"
});

import { getCookie, setCookie, delCookie } from "../utils/cookie";
export default {
  data() {
    //语言选择器;
    return {
        options: [{
          value: '选项1',
          label: '日本語'
        }, {
          value: '选项2',
          label: '中文'
        }, {
          value: '选项3',
          label: 'English'
        }],
        value: '',

      userTypes: [],
      resetForm: {
        verifyKey: "",
        key: "",
        password: "",
        resetPassword: ""
      },

      loginForm: {
        userCode: "",
        userPassword: "",
        remember: false
      },
      loginVerify: {
        userCodeVerify: false,
        userPasswordVerify: false
      },
      registerForm: {
        userTypeId: 1,
        userCode: "",
        userEmail: "",
        repeatUserEmail: "",
        userName: "",
        userPassword: "",
        repeatUserPassword: "",
        userTelephone: "",
        checked: false
      },

      sendForm: {
        verifyUserCode: ""
      },
      retrieve: {
        page: 1
        // steps1:{"aria-disabled":false,"aria-selected":true,"cls":"current"},
        // steps2:{"aria-disabled":true,"aria-selected":false,"cls":"disabled"}
      },
      isSubmit: false,
      registerSubmit: false,
      nextLoading: false,
      sendLodaing: false,
      sendLodaingMsg: this.$t("login.msg.sendRegEmail"),
      isLogin: false,
      userName: "",
      passwordFlag: true,
      registerTitle:this.$t("login.reg.registBut")
    };
  },
  created() {
    //this.sendLodaingMsg=this.$t('login.msg.sendRegEmail');
    let token = getCookie("token");
    let userId = getCookie("userId");

    if (token != null && userId != null && token != "" && userId != "") {
      this.isLogin = true;
      this.getUserAccountInfo(userId);
    } else {
      this.isLogin = false;
      this.userType();
      this.loadLoginInfo();
    }
  },
  mounted() {
    $(".accordion2 > .accordion-item.is-active")
      .children(".accordion-panel")
      .slideDown();

    $(".accordion2 > .accordion-item").click(function() {
      // Cancel the siblings
      $(this)
        .siblings(".accordion-item")
        .removeClass("is-active")
        .children(".accordion-panel")
        .slideUp();
      // Toggle the item
      $(this)
        .toggleClass("is-active")
        .children(".accordion-panel")
        .slideToggle("ease-out");
    });
    // let d=new Date();
    // let localTime = d.getTime();
    // let localOffset=d.getTimezoneOffset()*60000; //获得当地时间偏移的毫秒数
    // let utc = localTime + localOffset; //utc即GMT时间
    // let nd = new Date(utc);
    // let offset =9; //以日本时间为例，东9区
    // let hawaii = utc + (3600000*offset);
    // nd = new Date(hawaii);
  },
  methods: {
    async getUserAccountInfo(userId) {
      const { data: res } = await this.$http.get(
        "/service-user/user/getAccountInfo?userId=" + userId,
        {}
      );
      if (res.status == 200) {
        this.userName = res.data["userName"];
      }
    },
    LoginFree() {
      this.$router.push("/home");
    },

    signOut() {
      let loginVue = this;
      this.$http
        .get(
          "/service-user/user/logout?userId=" +
            window.sessionStorage.getItem("userId"),
          {}
        )
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            window.sessionStorage.removeItem("token");
            window.sessionStorage.removeItem("userId");
            window.sessionStorage.removeItem("selPsId");
            delCookie("token");
            delCookie("userId");
            loginVue.isLogin = false;
          } else {
            // swal(
            //   loginVue.$t("login.code." + data.status),
            //   loginVue.$t("login.code.goBack"),
            //   "warning"
            // );

            swal({
              //提示的标题
              title: loginVue.$t("login.msg.goBack"),
              //提示的文本
              text: loginVue.$t("interfaceMsg." + data.status),
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
            });
          }
        })
        .catch(function() {});
    },
    previous() {
      if (this.retrieve.page == 1) {
        this.retrieve.page = 1;
      }
      this.retrieve.page = this.retrieve.page - 1;
    },
    next() {
      (this.nextLoading = true),
        this.$validator.validateAll("verify").then(result => {
          if (result) {
            this.nextLoading = false;
            this.retrieve.page = this.retrieve.page + 1;
          } else {
            this.nextLoading = false;
          }
        });
    },

    //禁止粘贴事件
    paste(e) {
      e.preventDefault();
      return false;
    },
    changeLocale: function(locale) {
      this.$i18n.locale = locale;
      this.$validator.locale = locale;
      setCookie("locale", locale);
      $("html").attr("lang", locale);
      this.registerTitle=this.$t("login.reg.registBut")
    },
    //发送找回密码邮件
    async sendEmail() {
      var _this = this;
      this.sendLodaing = true;
      this.sendLodaingMsg = this.$t("login.msg.send"); //"发送中..."
      const { data: res } = await this.$http.post(
        "/service-user/user/forgetPassword?userCodeOrEmail=" +
          this.sendForm.verifyUserCode+"&languageId="+ this.$validator.locale
      );
      if (res.status == 200) {
        this.sendLodaing = false;
        this.sendLodaingMsg = this.$t("login.msg.sendRegEmail"); //"发送到注册邮箱"
         swal({
          //提示的标题
          title: _this.$t("login.msg.sendRegEmail"),
          //提示的文本
          text: _this.$t("login.msg.sendSuccess"),
          //类型：警告，还有success,error和info
          type: "success",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: _this.$t("login.msg.determine") //'确定'
        });
      } else {
        this.sendLodaing = false;
        this.sendLodaingMsg = this.$t("login.msg.sendRegEmail"); //"发送到注册邮箱"
        //swal(res.msg, this.$t("login.msg.goBack"), "warning");

        swal({
          //提示的标题
          title: _this.$t("login.msg.sendRegEmail"),
          //提示的文本
          text: _this.$t("interfaceMsg." + res.status),
          //类型：警告，还有success,error和info
          type: "warning",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: _this.$t("login.msg.determine") //'确定'
        });
      }
      //  /user/forgetPassword

      //swal("data.msg", "请返回重试", "warning");
    },

    //重置密码 async
    resetPwd() {
      this.retrieve.page = 3;
      this.retrieve.page = 3;
    },
    //关闭弹出框时清除验证信息
    registerClose() {
      $("#verifyModal").modal("hide"); //手动关闭verifyModal
      $("#registerModal").modal("hide"); //手动关闭verifyModal
      $(".modal-backdrop").remove();
      this.$validator.errors.clear("register");
      this.$validator.errors.clear("verify");
      this.$validator.errors.clear("reset");

      this.registerForm.userCode = "";
      this.registerForm.userEmail = "";
      this.registerForm.userName = "";
      this.registerForm.userPassword = "";
      this.registerForm.repeatUserPassword = "";
      this.registerForm.repeatUserEmail = "";
      this.retrieve.page = 1;
      this.sendForm.verifyUserCode = "";

      //swal("data.msg", "请返回重试", "warning");
    },

    //找回密码
    retrievePwd() {
      var _this = this;
      this.$validator.validateAll("reset").then(async result => {
        this.resetForm["key"] = this.resetForm["verifyKey"];
        if (result) {
          const { data: res } = await this.$http.post(
            "/service-user/user/updatePassword",
            this.resetForm
          );
          if (res.status == 200) {
            $("#verifyModal").modal("hide"); //手动关闭verifyModal
            $("#registerModal").modal("hide"); //手动关闭verifyModal
            $(".modal-backdrop").remove();
            this.$validator.errors.clear("register");
            this.$validator.errors.clear("verify");
            this.$validator.errors.clear("reset");

            this.registerForm.userCode = "";
            this.registerForm.userEmail = "";
            this.registerForm.userName = "";
            this.registerForm.userPassword = "";
            this.registerForm.repeatUserPassword = "";
            this.registerForm.repeatUserEmail = "";
            this.retrieve.page = 1;
            this.sendForm.verifyUserCode = "";
            //"修改成功","请登录"

            swal({
              //提示的标题
              title: _this.$t("login.msg.editSuccess"),
              //提示的文本
              text: _this.$t("login.msg.pleaseLgoin"),
              //类型：警告，还有success,error和info
              type: "success",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("login.msg.determine") //'确定'
            });
          } else {
            swal({
              //提示的标题
              title: _this.$t("login.msg.editFailed"),
              //提示的文本
              text: _this.$t("interfaceMsg." + res.status),
              //类型：警告，还有success,error和info
              type: "warning",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("login.msg.determine") //'确定'
            });
          }
        }
      });
      //swal("data.msg", "请返回重试", "warning");
    },
    //用户注册
    register() {
      this.registerSubmit = true;
      this.registerTitle = this.$t("login.reg.reigstTitle");
      if (!this.registerForm.checked) {
        swal({
          //提示的标题
          title: this.$t("login.msg.accept"), //"我已阅读并接受隐私条款",
          //提示的文本
          text: this.$t("login.msg.check"), //'请勾选我已阅读',
          //类型：警告，还有success,error和info
          type: "warning",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: this.$t("login.msg.determine") //'确定'
        });

        this.registerSubmit = false;
        this.registerTitle = this.$t("login.reg.registBut");
        return false;
      }

      this.$validator.validateAll("register").then(result => {
        if (this.registerForm.userName == "") {
          this.registerForm.userName = this.registerForm.userCode;
        }

        if (result) {
          let loginVue = this;
          this.$http
            .post("/service-user/user/register?languageId="+ this.$i18n.locale, this.registerForm)
            .then(function(ret) {
              let data = ret.data;
              if (data.status == 200) {
                loginVue.registerSubmit = false;
                loginVue.registerTitle = loginVue.$t("login.reg.registBut");
                loginVue.registerClose();
                loginVue.sendUserMail(data.data.userCode)
                swal({
                  //提示的标题
                  title: loginVue.$t("login.msg.registSuccess"), //"注册成功",
                  //提示的文本
                  text: "", //'',
                  //类型：警告，还有success,error和info
                  type: "success",
                  //显示取消按钮
                  showCancelButton: false,
                  //确认按钮内文本
                  confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
                });
                //swal("注册成功", "", "success");
              } else {
                loginVue.registerSubmit = false;
                loginVue.registerTitle = loginVue.$t("login.reg.registBut");
                swal({
                  //提示的标题
                  title: loginVue.$t("login.msg.registFailure"), //"注册失败",
                  //提示的文本
                  //text: loginVue.$t("login.msg.contactAdministrator"), //联系管理员
                  text: loginVue.$t("interfaceMsg." + res.status),
                  //类型：警告，还有success,error和info
                  type: "warning",
                  //显示取消按钮
                  showCancelButton: false,
                  //确认按钮内文本
                  confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
                });
                //swal("注册失败", data.msg, "warning");
              }
            })
            .catch(function() {
              loginVue.registerSubmit = false;
              loginVue.registerTitle = loginVue.$t("login.reg.registBut");
            });
        } else {
          this.registerSubmit = false;
          this.registerTitle = this.$t("login.reg.registBut");
          //"注册失败" 未通过验证

          swal({
            //提示的标题
            title: loginVue.$t("login.msg.registFailure"),
            //提示的文本
            text: loginVue.$t("login.msg.failedValidation"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
          });

          // swal(
          //   loginVue.$t("login.msg.registFailure"),
          //   loginVue.$t("login.msg.failedValidation"),
          //   "warning"
          // );
        }
      });
    },
    async sendUserMail(userCode){
       const { data: res } = await this.$http.get(
                      "/service-user/user/sendActiveMail?userCode=" +
                        userCode+"&languageId="+ this.$i18n.locale  
                    );
                    // if (res.status != 200 && res.status != 500) {
                     
                    // } 
    },
    //获取用户类型
    userType() {
      let loginVue = this;
      this.$http.get("/service-user/getUserTypes").then(function(ret) {
        let data = ret.data;
        if (data.status == 200) {
          loginVue.userTypes = data.data;
          if (loginVue.userTypes != null && loginVue.userTypes.length > 0) {
            loginVue.registerForm.userTypeId =
              loginVue.userTypes[0]["userTypeId"];
          }
        } else {
          //swal("data.msg", data.msg, "warning");
        }
      });
    },
    //登录
    login() {
      var _this=this;
      this.loginVerify.userCodeVerify = false;
      this.loginVerify.userPasswordVerify = false;

      if (this.loginForm.userCode == "") {
        this.loginVerify.userCodeVerify = true;
        return false;
      }
      if (this.loginForm.userPassword == "") {
        this.loginVerify.userPasswordVerify = true;
        return false;
      }

      this.sendForm.verifyUserCode = this.loginForm.userCode;
      let loginVue = this;
      this.isSubmit = true;
      this.$http
        .post("/service-user/user/login", this.loginForm)
        .then(function(ret) {
          let data = ret.data;
          if (data.status == 200) {
            let userName = loginVue.loginForm.userCode;
            let userPwd = ""; //loginVue.loginForm.userPassword;
            _this.$store.state.psId=null;
            //记住密码checkbox的勾选状态 和账
            let accountInfo = userName + "&" + userPwd;
            if (loginVue.loginForm.remember) {
              console.log("勾选了记住密码，现在开始写入cookie");
              setCookie("accountInfo", accountInfo, 1440 * 3);
            } else {
              console.log("没有勾选记住密码，现在开始删除账号cookie");
              delCookie("accountInfo");
            }

            let successData = data.data;
            if (typeof successData["token"] != "undefined") {
              window.sessionStorage.setItem("token", successData["token"]);
              setCookie("token", successData["token"]);
            }

            if (typeof successData["userId"] != "undefined") {
              window.sessionStorage.setItem("userId", successData["userId"]);
              setCookie("userId", successData["userId"]);
            }

            loginVue.$router.push("/home");
          } else {
            if (data.status == 502) {
              swal(
                {
                  //提示的标题
                  title: loginVue.$t("interfaceMsg." + data.status),
                  //提示的文本
                  text: loginVue.$t("login.msg.pleaseActivateEmail"), //'请到注册邮箱中激活',
                  //类型：警告，还有success,error和info
                  type: "warning",
                  //显示取消按钮
                  showCancelButton: true,
                  //确认按钮颜色
                  //confirmButtonColor: '#DD6B55',
                  //确认按钮内文本
                  confirmButtonText: loginVue.$t("login.msg.sendActiveEmail"), //'发送激活邮件',
                  //取消按钮内文本
                  cancelButtonText: loginVue.$t("login.msg.cancel"), //'取消',
                  closeOnConfirm: false,
                  closeOnCancel: false
                },
                async function(isConfirm) {

                  $('.idname').show()
                  if (isConfirm) {
                    //点击确认按钮之后进行的操作，这里success不能改
                    //发送激活邮件
                    const { data: res } = await loginVue.$http.get(
                      "/service-user/user/sendActiveMail?userCode=" +
                        loginVue.loginForm.userCode+"&languageId="+ _this.$i18n.locale,
                        
                    );
                    if (res.status == 200 || res.status==500) {
                      $('.idname').hide()
                      //'邮件发送成功','请到注册邮箱中查收'
                      swal({
                        //提示的标题
                        title: loginVue.$t("login.msg.sendActiveEmail"),
                        //提示的文本
                        text: loginVue.$t("login.msg.pleaseCheck"),
                        //类型：警告，还有success,error和info
                        type: "success",
                        //显示取消按钮
                        showCancelButton: false,
                        //确认按钮内文本
                        confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
                      });
                    } else {
                      // swal(
                      //   loginVue.$t("login.code." + res.status),
                      //   loginVue.$t("login.msg.goBack"),
                      //   "warning"
                      // );

                      swal({
                        //提示的标题
                        title: loginVue.$t("interfaceMsg." + res.status),
                        //提示的文本
                        text: _this.$t("interfaceMsg." + res.status),
                        //类型：警告，还有success,error和info
                        type: "warning",
                        //显示取消按钮
                        showCancelButton: false,
                        //确认按钮内文本
                        confirmButtonText: _this.$t("login.msg.determine") //'确定'
                      });
                    }
                  } else {
                    //点击取消按钮之后进行的操作，这里error不能改
                    //'取消'
                    //swal(loginVue.$t("login.msg.cancel"), "", "error");

                    swal({
                      //提示的标题
                      title: loginVue.$t("login.msg.cancel"),
                      //提示的文本
                      text: "",
                      //类型：警告，还有success,error和info
                      type: "error",
                      //显示取消按钮
                      showCancelButton: false,
                      //确认按钮内文本
                      confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
                    });
                  }
                }
              );
              //swal(data.msg, "请到注册邮箱中激活", "warning");
            } else {
              // swal(
              //   loginVue.$t("login.code." + data.status),
              //   loginVue.$t("login.msg.goBack"),
              //   "warning"
              // );
              swal({
                //提示的标题
                title: loginVue.$t("interfaceMsg." + data.status),
                //提示的文本
                text: loginVue.$t("login.msg.goBack"),
                //类型：警告，还有success,error和info
                type: "warning",
                //显示取消按钮
                showCancelButton: false,
                //确认按钮内文本
                confirmButtonText: loginVue.$t("login.msg.determine") //'确定'
              });
            }
            loginVue.isSubmit = false;
          }
        })
        .catch(function() {
          loginVue.isSubmit = false;
        });
    },

    loadLoginInfo() {
      let mySelf = this;

      let accountInfo = getCookie("accountInfo");

      //如果cookie里没有账号信息
      if (Boolean(accountInfo) == false) {
        return false;
      } else {
        //如果cookie里有账号信息

        let userName = "";
        let passWord = "";
        let index = accountInfo.indexOf("&");

        userName = accountInfo.substring(0, index);
        passWord = accountInfo.substring(index + 1);

        mySelf.loginForm.userCode = userName;
        mySelf.loginForm.userPassword = passWord;
        mySelf.loginForm.remember = true;
      }
    },
    passwordSee() {
      var tempFlag = this.passwordFlag;
      this.passwordFlag = !tempFlag;
    },

    /* 联系客服按钮 */
    handleContact() {
      swal({
          title:"",
          text: $("#ContactUs").html(),
          html:  true, 
          //确认按钮内文本
          confirmButtonText: this.$t("login.msg.determine") //'确定'
      });
    }
  }
};
</script>

<style scoped>
.content .body {
  transition: left 0.5s;
}

@media (min-width: 576px) {
  .myVideo {
    display: none;
  }
}

@media (min-width:1200px) {
  .myVideo {
    display: block;
  }
}
</style>
